<template>
  <footer>
    <v-container>
      <v-row align="center" justify="center" class="mt-5">
        <v-col cols="12" sm="4">
          <div class="text-body-1 text-center">Dra. Milena Mesquita | 2021</div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="mb-5">
        <v-btn
          href="https://instagram.com/milenamesquitaadv"
          target="_blank"
          class="mx-2"
          fab
          dark
          small
          color="#c73c6b"
        >
          <v-icon dark> mdi-instagram </v-icon>
        </v-btn>
        <v-btn
          href="https://api.whatsapp.com/send?phone=5521979310760&text=Ol%C3%A1!%20Preciso%20falar%20com%20um%20advogado."
          class="mx-2"
          fab
          dark
          small
          color="primary"
        >
          <v-icon dark> mdi-whatsapp </v-icon>
        </v-btn>
      </v-row>
    </v-container>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
footer {
  background: #000;
  color: #ffffff;
}
</style>
