<template>
  <div class="services">
    <v-container class="mx-auto py-5">
      <v-row align="center" justify="center">
        <v-col cols="11">
          <v-row align="center" justify="space-around">
            <v-col cols="12" sm="6" class="text-center">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 8 : 4"
                  :class="{ up: hover }"
                >
                  <h1 class="fonr-weight-regular">
                    Nome Negativado Indevidamente
                  </h1>
                  <h4 class="fonr-weight-regular body-2">
                    Ter um nome limpo na praça, é fundamental para o consumidor
                    adquirir empréstimos e comprar produtos e serviços.
                  </h4>
                  <h4 class="fonr-weight-regular body-2">
                    Quando o consumidor tem o nome “Negativado Indevidamente” no
                    SPC e SERASA fica impedido de abrir conta bancária, obter
                    cartão de crédito, financiamento, empréstimos e etc. Se você
                    está passando por uma situação semelhante, entre em contato,
                    iremos ajuda-lo a resolver o problema e ainda, se for o
                    caso, terá direito a uma indenização.
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="12" sm="6" class="text-center">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 8 : 4"
                  :class="{ up: hover }"
                >
                  <h1 class="fonr-weight-regular">
                    Multas e Cortes Indevido de Serviços de Energia
                  </h1>
                  <h4 class="fonr-weight-regular body-2">
                    Energia elétrica é serviço essencial! Corte de energia
                    (indevido) ou cobranças de multas abusivas, podem gerar
                    indenizações por dano moral.
                  </h4>
                  <h4 class="fonr-weight-regular body-2">
                    Se você tem dúvidas se é ou não o seu caso, entre em contato
                    que analisaremos a situação e o ajudaremos a tomar as
                    devidas providências.
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ServicesSection",
};
</script>

<style scoped>
.services {
  background: #000;
  color: #ffffff;
  padding: 5rem 0;
}

.card {
  min-height: 325px;
  padding: 10px;
  transition: 0.5s ease-out;
}
.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}
.card h1 {
  margin-bottom: 10px;
}

.up {
  transform: translateY(-10px);
  transition: 0.5s ease-out;
}
</style>
