<template>
  <section>
    <v-container>
      <v-row align="center" style="height: 95vh">
        <v-col cols="11 " md="7">
          <div class="text-overline">Olá! Sou a</div>
          <h1 class="display-2 font-weight-bold">Dra. Milena Mesquita</h1>
          <h1 class="font-weight-light">
            Advogada Especialista em Direito do Consumidor.
          </h1>
          <div class="text-body-1 mb-5">
            Entre em contato, teremos prazer em ajudar a solucionar seus
            problemas jurídicos.
          </div>
          <v-btn
            href="https://api.whatsapp.com/send?phone=5521979310760&text=Ol%C3%A1!%20Preciso%20falar%20com%20um%20advogado."
            color="teal"
            dark
          >
            <span class="mr-2">Entre em contato</span>
            <v-icon>mdi-whatsapp</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <img
        src="../assets/foto.png"
        height="560px"
        class="foto hidden-sm-and-down"
        alt="Dra. Milena Mesquita"
      />
    </v-container>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
section {
  width: 100%;
  height: 95vh;
  background-image: url("../assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.foto {
  height: 100vh;
  position: absolute;
  bottom: 0px;
  left: 80%;
  transform: translateX(-80%);
}
</style>
