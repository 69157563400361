<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="10" xl="8">
        <v-card>
          <v-card-text>
            <v-form
              @submit.prevent="sendEmail"
              ref="form"
              lazy-validation
              v-model="valid"
            >
              <div class="text-h4 text-center mb-2">Conte-nos o seu caso</div>
              <div class="text-body-1 textpgrey-lighten-1 text-center mb-3">
                Deixe sua mensagem abaixo ou envie um Whatsapp para (21)
                979310760
              </div>
              <v-row>
                <v-col cols="12" md="4" class="py-none my-none">
                  <v-text-field
                    class="py-none my-none"
                    label="Nome"
                    outlined
                    name="name"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    class="my-none"
                    label="Telefone"
                    outlined
                    name="phone"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    class="my-none"
                    label="E-mail"
                    type="email"
                    outlined
                    name="email"
                    :rules="emailRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-textarea
                outlined
                name="body"
                label="Informe seu caso Aqui"
                :rules="requiredRule"
                value=""
              ></v-textarea>
              <v-row no-gutters justify="space-between">
                <div cols="12" sm="4">
                  <v-btn
                    class="ma-2 white--text"
                    :loading="loading"
                    :disabled="loading"
                    color="teal"
                    type="submit"
                  >
                    Enviar
                  </v-btn>
                </div>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar :color="snackbar.status" v-model="snackbar.show">
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import emailjs from "emailjs-com";
export default {
  name: "ContactSection",
  data() {
    return {
      valid: true,
      snackbar: {
        status: "",
        message: "",
        show: false,
      },
      loading: false,
      requiredRule: [(v) => !!v || "Preencha este campo"],
      emailRules: [
        (v) => !!v || "Preencha este campo",
        (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
      ],
    };
  },
  methods: {
    sendEmail(e) {
      if (this.$refs.form.validate()) {
        this.loading = true;
        emailjs
          .sendForm(
            "gmail-message-milena-adv",
            "template_0fyu5sj",
            e.target,
            "user_IgMM7fbkJQCWkOeoHqgXs"
          )
          .then(() => {
            this.showSnackBar(
              true,
              "Suas informações foram enviadas com sucesso."
            );
            e.target.reset();
          })
          .catch((error) => {
            this.showSnackBar(false, "Ops! Ocorreu um erro, tente novamente.");
            console.log("FAILED...", error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    /**
     * status: boolean
     * message: strign
     */
    showSnackBar(status, message) {
      this.snackbar = {
        status: status ? "success" : "error",
        show: true,
        message: message,
      };
    },
  },
};
</script>
