<template>
  <div class="services">
    <v-container class="mx-auto">
      <div class="text-h4 mb-5">
        Atuamos nas áreas que envolvam relação de consumo de modo geral:
      </div>
      <v-row align="center" justify="center">
        <v-col cols="10 pt-5">
          <v-row justify="space-around">
            <v-col cols="12" sm="6">
              <ul>
                <li>Problemas com Assistência Técnica;</li>

                <li>Problemas com Internet Banda Larga;</li>
                <li>Problemas com Plano de Saúde;</li>
                <li>Problemas com Telefonia;</li>
                <li>Problemas com TV por assinatura;</li>
                <li>Suspensão de Energia, Água e Gás;</li>
                <li>Produtos com defeito;</li>
                <li>Produtos Entregues com Atraso;</li>
                <li>Produtos não entregues;</li>
                <li>Serviços não contratados;</li>
                <li>Serviços Suspensos Indevidamente;</li>
                <li>
                  Problemas com Cias. Aéreas (Atrasos e cancelamentos de vôos);
                </li>
              </ul>
            </v-col>
            <v-col cols="12" sm="6">
              <ul>
                <li>Cartões Não solicitados;</li>
                <li>Cobranças por compras não realizadas;</li>
                <li>Compras não reconhecidas;</li>
                <li>Débito pago e nome permaneceu negativado;</li>
                <li>Débitos Indevidos na Conta;</li>
                <li>Empréstimos não contratados;</li>
                <li>Multas Indevidas de Luz e Água;</li>
                <li>Negativa de Cobertura de Garantia de Produto;</li>
                <li>Negativação indevida;</li>
                <li>
                  Negativas de Planos de Saúde (Próteses, Cirurgias e
                  Internações);
                </li>
                <li>Problemas com Abastecimento de Luz, Água e Gás;</li>
              </ul>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ServicesSection",
};
</script>

<style scoped>
.services {
  background: #e6e6e6;
  padding: 5rem 0;
}
</style>
