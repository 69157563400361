<template>
  <v-app-bar app :color="color" :flat="flat" dark :class="{ expand: flat }">
    <div>
      <v-img
        alt="Logo"
        class="shrink mt-2"
        contain
        src="../assets/logo2.png"
        width="100"
      />
    </div>

    <v-spacer></v-spacer>

    <v-btn
      href="https://api.whatsapp.com/send?phone=5521979310760&text=Ol%C3%A1!%20Preciso%20falar%20com%20um%20advogado."
      text
      color="teal"
      dark
    >
      <span class="hidden-sm-and-down mr-2">Como posso ajudar?</span>
      <v-icon>mdi-whatsapp</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: "Navbar",
  props: {
    color: String,
    flat: Boolean,
  },
  data() {
    return {
      isXs: false,
    };
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
  },
  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>

<style scoped>
.v-toolbar {
  transition: 0.6s;
}
.expand {
  height: 80px !important;
  padding-top: 10px;
}
</style>
