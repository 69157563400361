<template>
  <v-app>
    <navbar :color="color" :flat="flat" />
    <v-main class="pt-0">
      <home-section />

      <contact-section class="contact-form" />

      <!-- <feature-section /> -->
      <services-section />
      <card-section />
      <about-section />
    </v-main>
    <footer-section />
    <v-scale-transition>
      <v-btn
        dark
        fab
        v-scroll="onScroll"
        fixed
        bottom
        right
        color="primary"
        href="https://api.whatsapp.com/send?phone=5521979310760&text=Ol%C3%A1!%20Preciso%20falar%20com%20um%20advogado."
      >
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>
    </v-scale-transition>
  </v-app>
</template>

<script>
import HomeSection from "@/components/HomeSection.vue";
import Navbar from "@/components/Navbar.vue";
import ContactSection from "@/components/ContactSection.vue";
// import FeatureSection from "@/components/FeatureSection.vue";
import AboutSection from "@/components/AboutSection.vue";
import FooterSection from "./components/FooterSection.vue";
import ServicesSection from "./components/ServicesSection.vue";
import CardSection from "./components/CardSection.vue";

export default {
  name: "App",
  components: {
    HomeSection,
    Navbar,
    ContactSection,
    // FeatureSection,
    AboutSection,
    FooterSection,
    ServicesSection,
    CardSection,
  },
  data() {
    return {
      color: "",
      flat: null,
      fab: null,
    };
  },
  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
  },
  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
<style lang="scss">
body {
  background-color: #f6f5f5;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
.contact-form {
  display: flex;
  margin: -7% auto 20px auto;
}
footer {
  background: #000;
  color: #ffffff;
}
</style>
